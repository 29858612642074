<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  listOrderTotalPlanDetail,
  sumListOrderTotalPlanDetail,
  listOrderTotalGoodsDetail,
  sumListOrderTotalGoodsDetail,
  listOrderTotalOrderDetail,
sumListOrderTotalOrderDetail
} from '@/api/produce/report/orderTotal' //生产计划汇总表
export default {
  name: "orderTotal",
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "生产订单汇总表",
        rowKey: "billIda",
        search: [
          {
            label: "单据日期",
            type: "daterange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "goodsNos", label: "商品编码" },
              { filter: "goodsNames", label: "商品名称" },
              { filter: "barcodes", label: "条码" },
            ],
          },
          {
            label: "生产日期",
            type: "daterange",
            seniorSearch: true,
            clearable: true,
            model: "",
            filterFnc: ([a, b] = []) => ({ beginProduceDate: a, endProduceDate: b }),
          },
          {
            type: 'input',
            tip: '单据编号',
            label: '单据编号',
            seniorSearch: true,
            model: '',
            clearable: true,
            filter: 'billNo'
          },
          this.$select({
            key: "listGoods",
            option: {
              filter: "goodsIds",
              label: "商品",
              seniorSearch: true,
              option: {
                multiple: true,
                // buttons: [
                //   {
                //     type: "more",
                //     option: {
                //       title: "选择商品",
                //       width: 1250,
                //       type: "TreeAndTable",
                //       formData: this.$dialog({ key: "goods" }),
                //     },
                //   },
                // ],
              },
            },
          }),
          this.$select({
            key: "listProduceDept",
            option: {
              filter: "produceDeptIds",
              label: "生产工厂",
              seniorSearch: true,
              option: {
                multiple: true,
                // buttons: [
                //   {
                //     type: "more",
                //     option: {
                //       title: "选择生产工厂",
                //       width: 1250,
                //       type: "TreeAndTable",
                //       formData: this.$dialog({ key: "shop" }),
                //     },
                //   },
                // ],
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "order",
        tabsColumns: [
          {
            getListApi: listOrderTotalOrderDetail,
            getSummaryApi: sumListOrderTotalOrderDetail,
            uuid: "e8dc4bf0-aedd-2697-f272-1233f13e37ebb",
            title: "生产订单汇总表(按订单)",
            type: "order",
            exportOption: {
              fastExportUrl:
                "/api/system/produce/report/exportOrderTotalOrderDetail",
              exportName: "生产订单汇总表(按订单)",
            },
            columns: [
              {
                prop: "planBillNo",
                label: "生产计划单号",
                minWidth: 160,
              },
              {
                prop: "planBillDate",
                label: "单据日期",
                minWidth: 100,
              },
              {
                prop: "orderBillTypeName",
                label: "订单类型",
                minWidth: 100,
              },
              {
                prop: "orderBillNo",
                label: "订单号",
                minWidth: 160,
              },
              {
                prop: "orderBillDate",
                label: "订单日期",
                minWidth: 100,
              },
              {
                prop: "produceGroupName",
                label: "生产组",
                minWidth: 120,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 120,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 120,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 100,
              },
              {
                prop: "basUnitName",
                label: "基本单位",
                minWidth: 100,
              },
              {
                prop: "produceOrderList",
                align: "center",
                label: "生产班次",
                children: () =>
                  this.options.list?.[0]?.orderList?.map?.((x) => ({
                    ...x,
                    label: x.produceOrderName,
                    prop: `produceOrderList.${x.produceOrderId}.produceOrderId.basUnitQty`,
                    minWidth: 110,
                    summary: true,
                    getValue: (row, prop) =>
                      row.produceOrderList?.find((y) => y.produceOrderId === x.produceOrderId)
                        ?.basUnitQty || 0,
                  })) || [],
              },
              {
                prop: "basUnitQty",
                label: "合计汇总数量",
                minWidth: 120,
              },
              {
                prop: "batchUnitQty",
                label: "生产批数",
                minWidth: 120,
              },
              {
                prop: "basSalePrice",
                label: "标准售价",
                minWidth: 100,
              },
              {
                prop: "basSaleMoney",
                label: "售价金额",
                minWidth: 120,
              },
              {
                prop: "basPurPrice",
                label: "标准进价",
                minWidth: 100,
              },
              {
                prop: "basPurMoney",
                label: "进价金额",
                minWidth: 120,
              },
            ],
            summary: ["basUnitQty", "batchUnitQty", 'basSaleMoney', 'basPurMoney'],
          },
          {
            getListApi: listOrderTotalPlanDetail,
            getSummaryApi: sumListOrderTotalPlanDetail,
            uuid: "e8dc4bf0-aedd-2697-f272-1233f13e37eb",
            title: "生产订单汇总表(按生产计划单)",
            type: "planOrder",
            exportOption: {
              fastExportUrl:
                "/api/system/produce/report/exportOrderTotalPlanDetail",
              exportName: "生产订单汇总表(按生产计划单)",
            },
            columns: [
              {
                prop: "planBillNo",
                label: "生产计划单号",
                minWidth: 160,
              },
              {
                prop: "planBillDate",
                label: "单据日期",
                minWidth: 100,
              },
              {
                prop: "produceGroupName",
                label: "生产组",
                minWidth: 120,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 120,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 120,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 100,
              },
              {
                prop: "basUnitName",
                label: "基本单位",
                minWidth: 100,
              },
              {
                prop: "produceOrderList",
                align: "center",
                label: "生产班次",
                children: () =>
                  this.options.list?.[0]?.orderList?.map?.((x) => ({
                    ...x,
                    label: x.produceOrderName,
                    prop: `produceOrderList.${x.produceOrderId}.produceOrderId.basUnitQty`,
                    minWidth: 110,
                    summary: true,
                    getValue: (row, prop) =>
                      row.produceOrderList?.find((y) => y.produceOrderId === x.produceOrderId)
                        ?.basUnitQty || 0,
                  })) || [],
              },
              {
                prop: "basUnitQty",
                label: "合计汇总数量",
                minWidth: 120,
              },
              {
                prop: "batchUnitQty",
                label: "生产批数",
                minWidth: 120,
              },
              {
                prop: "basSalePrice",
                label: "标准售价",
                minWidth: 100,
              },
              {
                prop: "basSaleMoney",
                label: "售价金额",
                minWidth: 120,
              },
              {
                prop: "basPurPrice",
                label: "标准进价",
                minWidth: 100,
              },
              {
                prop: "basPurMoney",
                label: "进价金额",
                minWidth: 120,
              },
            ],
            summary: ["basUnitQty", "batchUnitQty", 'basSaleMoney', 'basPurMoney'],
          },
          {
            getListApi: listOrderTotalGoodsDetail,
            getSummaryApi: sumListOrderTotalGoodsDetail,
            uuid: "dda76d32-2de9-4c21-0959-c4dc0664b8c",
            title: "生产订单汇总表(按商品)",
            type: "categoryBack",
            exportOption: {
              fastExportUrl:
                "/api/system/produce/report/exportOrderTotalGoodsDetail",
              exportName: "生产订单汇总表(按商品)",
            },
            columns: [
              {
                prop: "produceGroupName",
                label: "生产组",
                minWidth: 120,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 120,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 120,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 100,
              },
              {
                prop: "basUnitName",
                label: "基本单位",
                minWidth: 100,
              },
              {
                prop: "produceOrderList",
                align: "center",
                label: "生产班次",
                children: () =>
                  this.options.list?.[0]?.orderList?.map?.((x) => ({
                    ...x,
                    label: x.produceOrderName,
                    prop: `produceOrderList.${x.produceOrderId}.produceOrderId.basUnitQty`,
                    minWidth: 110,
                    summary: true,
                    getValue: (row, prop) =>
                      row.produceOrderList?.find((y) => y.produceOrderId === x.produceOrderId)
                        ?.basUnitQty || 0,
                  })) || [],
              },
              {
                prop: "basUnitQty",
                label: "合计汇总数量",
                minWidth: 120,
              },
              {
                prop: "batchUnitQty",
                label: "生产批数",
                minWidth: 120,
              },
              {
                prop: "basSalePrice",
                label: "标准售价",
                minWidth: 100,
              },
              {
                prop: "basSaleMoney",
                label: "售价金额",
                minWidth: 120,
              },
              {
                prop: "basPurPrice",
                label: "标准进价",
                minWidth: 100,
              },
              {
                prop: "basPurMoney",
                label: "进价金额",
                minWidth: 120,
              },
            ],
            summary: ["basUnitQty", "batchUnitQty", 'basSaleMoney', 'basPurMoney'],
          },
        ],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        default:
          break;
      }
    },
  },
};
</script>
