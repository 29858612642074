var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap", attrs: { id: "wrap" } },
    [
      _c(
        "cardTitleCom",
        { attrs: { "card-title": "查询", id: "search-card" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "navSearch" },
              [
                _c("seniorSearch", {
                  attrs: {
                    filterTimeStr: "today",
                    getList: _vm.getList,
                    "drop-down-list": _vm.dropDownList,
                    "is-search-input": true,
                    "is-search-input-title": "商品编码、条码、商品名称",
                    dateTimeType: "daterange",
                  },
                  on: { isShowHighCom: _vm.getIsShowHigh },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showHigh,
                        expression: "showHigh",
                      },
                    ],
                    staticClass: "marT10",
                  },
                  [
                    _c("div", { staticClass: "x-f" }, [
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c("span", { staticClass: "fS14MR10" }, [
                            _vm._v("生产日期"),
                          ]),
                          _c("el-date-picker", {
                            staticClass: "inputWidth marR15",
                            attrs: {
                              size: "mini",
                              type: "daterange",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "value-format": "yyyy-MM-dd",
                              clearable: "",
                            },
                            model: {
                              value: _vm.datetime,
                              callback: function ($$v) {
                                _vm.datetime = $$v
                              },
                              expression: "datetime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c("span", { staticClass: "fS14MR10" }, [
                            _vm._v("单据编号"),
                          ]),
                          _c("el-input", {
                            staticClass: "inputWidth marR15",
                            attrs: { placeholder: "全部", size: "mini" },
                            model: {
                              value: _vm.queryParams.billNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "billNo", $$v)
                              },
                              expression: "queryParams.billNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c("span", { staticClass: "fS14MR10" }, [
                            _vm._v("商品"),
                          ]),
                          _c("SelectRemote", {
                            staticClass: "marR15",
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({ key: "listGoods" }).option,
                            },
                            model: {
                              value: _vm.queryParams.goodsIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "goodsIds", $$v)
                              },
                              expression: "queryParams.goodsIds",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c("span", { staticClass: "fS14MR10" }, [
                            _vm._v("生产工厂"),
                          ]),
                          _c("SelectRemote", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({
                                key: "listProduceDept",
                              }).option,
                            },
                            model: {
                              value: _vm.queryParams.produceDeptIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "produceDeptIds", $$v)
                              },
                              expression: "queryParams.produceDeptIds",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "cardTitleCom",
        {
          staticClass: "headGoodsTable",
          attrs: { "card-title": "生产计划统计表" },
          scopedSlots: _vm._u([
            {
              key: "rightCardTitle",
              fn: function () {
                return [
                  _c(
                    "el-button",
                    {
                      staticClass: "marR10",
                      attrs: { icon: "el-icon-download", size: "mini" },
                      on: { click: _vm.handleExport },
                    },
                    [_vm._v("导出 ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "tableContent" },
              [
                _c(
                  "el-tabs",
                  {
                    on: { "tab-click": _vm.handleClick },
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      {
                        attrs: { label: "生产计划单明细查询", name: "detail" },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            ref: "multipleTable1",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "show-summary": "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "summary-method": _vm.getSummaries,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单据日期",
                                align: "center",
                                prop: "billDate",
                                "min-width": "120",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单据编号",
                                align: "center",
                                "min-width": "200",
                                prop: "billNo",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "生产日期",
                                align: "center",
                                prop: "produceDate",
                                "min-width": "120",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "生产工厂编号",
                                align: "center",
                                prop: "deptNo",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "生产工厂",
                                align: "center",
                                prop: "deptName",
                                "min-width": "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "领料状态",
                                align: "center",
                                prop: "leadMaterialStatusName",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品编码",
                                align: "center",
                                prop: "goodsNo",
                                "min-width": "150",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品名称",
                                align: "center",
                                prop: "goodsName",
                                "min-width": "180",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "条码",
                                align: "center",
                                prop: "barcode",
                                "min-width": "150",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "规格",
                                align: "center",
                                prop: "goodsSpec",
                                "min-width": "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "基本单位",
                                align: "center",
                                prop: "basUnitName",
                                "min-width": "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "基本单位数量",
                                sortable: "",
                                align: "center",
                                prop: "basUnitQty",
                                "min-width": "130",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单位",
                                align: "center",
                                prop: "unitName",
                                "min-width": "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "数量",
                                sortable: "",
                                align: "center",
                                prop: "unitQty",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "基本单位售价",
                                sortable: "",
                                align: "center",
                                prop: "basSalePrice",
                                "min-width": "130",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "基本单位售价金额",
                                sortable: "",
                                align: "center",
                                prop: "basSaleMoney",
                                "min-width": "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "基本单位进价",
                                sortable: "",
                                align: "center",
                                prop: "basPurPrice",
                                "min-width": "130",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "基本单位进价金额",
                                sortable: "",
                                align: "center",
                                prop: "basPurMoney",
                                "min-width": "150",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "生产工厂汇总查询", name: "dept" } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            ref: "multipleTable2",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "show-summary": "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "summary-method": _vm.getSummaries,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "生产工厂编码",
                                align: "center",
                                prop: "deptNo",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "生产工厂",
                                align: "center",
                                prop: "deptName",
                                "min-width": "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品编码",
                                align: "center",
                                prop: "goodsNo",
                                "min-width": "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品名称",
                                align: "center",
                                prop: "goodsName",
                                "min-width": "180",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "条码",
                                align: "center",
                                prop: "barcode",
                                "min-width": "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "规格",
                                align: "center",
                                prop: "goodsSpec",
                                "min-width": "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "基本单位",
                                align: "center",
                                prop: "basUnitName",
                                "min-width": "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "基本单位数量",
                                sortable: "",
                                align: "center",
                                prop: "basUnitQty",
                                "min-width": "130",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "基本单位售价",
                                sortable: "",
                                align: "center",
                                prop: "basSalePrice",
                                "min-width": "130",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "基本单位售价金额",
                                sortable: "",
                                align: "center",
                                prop: "basSaleMoney",
                                "min-width": "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "基本单位进价",
                                sortable: "",
                                align: "center",
                                prop: "basPurPrice",
                                "min-width": "130",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "基本单位进价金额",
                                sortable: "",
                                align: "center",
                                prop: "basPurMoney",
                                "min-width": "150",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "商品汇总查询", name: "goods" } },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            ref: "multipleTable3",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "show-summary": "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "summary-method": _vm.getSummaries,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品编码",
                                align: "center",
                                prop: "goodsNo",
                                "show-overflow-tooltip": "",
                                "min-width": "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品名称",
                                align: "center",
                                prop: "goodsName",
                                "min-width": "180",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "条码",
                                align: "center",
                                prop: "barcode",
                                "show-overflow-tooltip": "",
                                "min-width": "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "规格",
                                align: "center",
                                prop: "goodsSpec",
                                "min-width": "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "基本单位",
                                align: "center",
                                prop: "basUnitName",
                                "min-width": "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "基本单位数量",
                                sortable: "",
                                align: "center",
                                prop: "basUnitQty",
                                "min-width": "130",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "基本单位售价",
                                sortable: "",
                                align: "center",
                                prop: "basSalePrice",
                                "min-width": "130",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "基本单位售价金额",
                                sortable: "",
                                align: "center",
                                prop: "basSaleMoney",
                                "min-width": "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "基本单位进价",
                                sortable: "",
                                align: "center",
                                prop: "basPurPrice",
                                "min-width": "130",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "基本单位进价金额",
                                sortable: "",
                                align: "center",
                                prop: "basPurMoney",
                                "min-width": "150",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("bottomPage", {
                  attrs: {
                    getList: _vm.getList,
                    "is-select-all": false,
                    "total-com": _vm.total,
                  },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }