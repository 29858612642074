<template>
  <div class="wrap x-f" id="wrap">
    <!-- 左边树装选择 -->
    <categoryTreeCard style="margin-right: 0" categoryTreeTitle="生产工厂" @treeNodeId="getTreeNodeId"
      :boxHeight="boxHeight" :boxInternalHeight="boxInternalHeight" :categoryTreeOptions="produceDeptList"
      :defaultProps="defaultProps" />
    <div class="right">
      <!-- 关系情况 -->
      <div class="content" v-loading="availableLoading">
        <cardTitleCom cardTitle="生产组" class="cardDetail">
          <template slot="cardContent">
            <div class="tableContent">
              <el-form ref="form" :rules="rules" :model="formDetail">
                <el-table tooltip-effect="dark" :data="tableDataDetail" border v-loading="loadingDetail"
                  @row-click="handleRowClick" @cell-mouse-enter="
                    (row) => cellMouseEnter(row, 'tableDataDetail')
                  " @cell-mouse-leave="cellMouseLeave('tableDataDetail')" :max-height="tableHeight"
                  :height="tableHeight">
                  <el-table-column width="55" align="center">
                    <template v-slot="scope">
                      <el-radio v-model="scope.row.selected" :label="true">{{
                        ""
                      }}</el-radio>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" :label="tableCellLabel" width="80">
                    <template v-slot="scope">
                      <i v-if="scope.row.hoverRow" @click.stop="rowDetail('push', scope.$index)"
                        class="el-icon-circle-plus operatePush"></i>
                      <i v-if="scope.row.hoverRow" @click.stop="
                          rowDetail(
                            'del',
                            scope.$index,
                            scope.row.produceGroupId
                          )
                        " class="el-icon-remove operateDel"></i>
                      <div v-if="!scope.row.hoverRow">
                        {{ scope.$index + 1 }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="生产组编号" align="center" prop="produceGroupNo" min-width="120">
                    <template slot-scope="scope">
                      <!-- <el-form-item> -->
                      <el-input size="mini" maxlength="20" v-model="scope.row.produceGroupNo"
                        @blur="blurGroup(scope.$index)" @focus="handleRowClick(scope.row)" />
                      <!-- </el-form-item> -->
                    </template>
                  </el-table-column>
                  <el-table-column label="生产组名称" align="center" prop="produceGroupName" min-width="150">
                    <template slot-scope="scope">
                      <!-- <el-form-item> -->
                      <el-input size="mini" maxlength="40" v-model="scope.row.produceGroupName"
                        @blur="blurGroup(scope.$index)" @focus="handleRowClick(scope.row)" />
                      <!-- </el-form-item> -->
                    </template>
                  </el-table-column>
                </el-table>
              </el-form>
            </div>
          </template>
        </cardTitleCom>
        <cardTitleCom :cardTitle="selectGroupName" class="cardAvailable">
          <template slot="rightCardTitle">
            <el-button style="margin-right:10px" type="primary" size="mini"
              @click="$router.push({ path: '/produce/sets/produceGroup/goods' })">生产组商品查询</el-button>
          </template>
          <template slot="cardContent">
            <div class="tableContent">
              <el-table tooltip-effect="dark" :data="tableDataAvailable" border @cell-mouse-enter="
                  (row) => cellMouseEnter(row, 'tableDataAvailable')
                " @cell-mouse-leave="cellMouseLeave('tableDataAvailable')" :max-height="tableHeight"
                :height="tableHeight">
                <el-table-column align="center" :label="tableCellLabel" width="80">
                  <template v-slot="scope">
                    <i v-if="scope.row.hoverRow" @click.stop="rowAvailable('push', scope.$index)"
                      class="el-icon-circle-plus operatePush"></i>
                    <i v-if="scope.row.hoverRow" @click.stop="
                        rowAvailable('del', scope.$index, scope.row.goodsId)
                      " class="el-icon-remove operateDel"></i>
                    <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="商品编码" align="center" prop="goodsNo" min-width="150">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.goodsNo" :disabled="disabled">
                      <i slot="suffix" class="el-icon-more more" @click="selectGoods"></i>
                    </el-input>
                  </template>
                </el-table-column>
                <!-- @keyup.enter.native="
                      keyup('goodsNo', $event, scope.$index, scope.row.goodsId)
                    " -->
                <el-table-column label="商品名称" align="center" min-width="150" prop="goodsName" />
                <el-table-column label="规格" align="center" prop="goodsSpec" min-width="100" />
                <el-table-column label="单位" align="center" prop="unitName" min-width="100" />
              </el-table>
            </div>
          </template>
        </cardTitleCom>
      </div>
    </div>
    <!-- 弹窗 -->
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import bottomPage from "@/views/components/bottomPage"; //底部分页
import {
  produceGroupList,
  produceGroupAdd,
  produceGroupUpdate,
  produceGroupRemove,
  produceGroupGoodsList,
  produceGroupGoodsAdd,
  produceGroupGoodsRemove,
} from "@/api/produce/sets/produceGroup"; //生产组、生产组商品详情信息
import Dialog from "@/components/Dialog";
import { queryProduceBasDeptList } from "@/api/produce/sets/produceDept"; //生产仓库
import categoryTreeCard from "@/views/components/categoryTreeCard"; //选择类别
export default {
  name: "produceGroup",
  components: { bottomPage, cardTitleCom, Dialog, categoryTreeCard },
  data() {
    return {
      produceDeptList: [],
      defaultProps: {
        id: "produceDeptId",
        label: "produceDeptName",
      },
      //弹窗配置
      dialogOptions: {
        title: "选择商品",
        width: 1250,
        show: false,
        type: "TreeAndTable",
        formData: this.$dialog({ key: "goods" }),
      },
      tableCellLabel: "序号",
      selectGroupName: "生产组产品", //当前选中的生产组的名称
      loadingDetail: false, //生产组表格的加载
      availableLoading: false, //商品表格的加载
      formDetail: {}, //生产组信息
      formAvailable: {}, //生产组商品信息
      rules: {
        produceGroupNo: [
          { required: true, message: "编号不能为空", trigger: "blur" },
        ],
        produceGroupName: [
          { required: true, message: "名称不能为空", trigger: "blur" },
        ],
      }, //校验表格
      tableDataDetail: [
        {
          lineId: Date.now(),
        },
      ], //原料明细表格数据
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 99999,
      },
      tableDataDetailContrast: [], //对比数据
      tableDataAvailable: [], //可用物料表格数据
      disabled: false, //禁用状态
      vivwH: 0, //页面高度
    };
  },
  computed: {
    tableHeight() {
      return this.vivwH - 70;
    },
    boxHeight() {
      return this.vivwH - 10;
    },
    boxInternalHeight() {
      return this.vivwH - 38;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById("wrap").clientHeight;
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  async created() {
    const res = await queryProduceBasDeptList();
    this.produceDeptList = res.data;
  },
  methods: {
    //点击类别树
    async getTreeNodeId(value) {
      this.queryParams.produceDeptId = value;
      await this.getList();
    },
    //弹窗确定事件
    async handleEvent(type, row) {
      switch (type) {
        case "dialogChange":
          //商品
          this.getGoodS(this.dialogOptions.formData.table.check);
          break;
        default:
          break;
      }
    },
    //商品弹窗
    selectGoods() {
      this.dialogOptions.formData = this.$dialog({ key: "goods" });
       this.dialogOptions.formData.table.pagination = {
        ...this.dialogOptions.formData.table.pagination,
        goodsTypes: [0, 1, 2, 4],
      };
      this.dialogOptions.show = true;
    },
    handleResize() {
      this.vivwH = document.getElementById("wrap").clientHeight;
    },
    //表格行hover时,显示操作加减号
    cellMouseEnter(row, table) {
      let eleIndex = this[table].indexOf(row);
      for (let index = 0; index < this[table].length; index++) {
        const element = this[table][index];
        if (eleIndex == index) {
          element["hoverRow"] = true;
        } else {
          element["hoverRow"] = false;
        }
      }
      this.tableCellLabel = "操作";
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave(table) {
      // 移除hover的事件
      for (let index = 0; index < this[table].length; index++) {
        const element = this[table][index];
        element["hoverRow"] = false;
      }
      this.tableCellLabel = "序号";
    },
    submitForm() {
      this.$message.success("保存成功");
      // if (this.formDetail.produceGroupNo && this.formDetail.produceGroupName) {
      //   this.addGroup()
      //   this.addGroupGoods()
      // } else {
      //   this.$message({
      //     type: 'warning',
      //     message: '生产组编号或生产组名称不能为空!'
      //   })
      // }
    },
    //生产组输入框焦点
    focusGroup(row) {
      this.formDetail = row; // 获取当前行的信息
    },
    //生产组输入框失焦
    async blurGroup(index) {
      try {
        this.availableLoading = true;
        //判断编号、名称是否填写
        if (
          this.formDetail.produceGroupNo &&
          this.formDetail.produceGroupName
        ) {
          if (this.formDetail.produceGroupId) {
            //判断生产组是不是修改
            if (
              JSON.stringify(this.tableDataDetailContrast[index]) !=
              JSON.stringify(this.formDetail)
            ) {
              await this.updateGroup(this.queryParams.produceDeptId);
            }
          } else {
            //新增生产组
            await this.addGroup(this.queryParams.produceDeptId);
          }
        }
        this.availableLoading = false;
      } catch (error) {
        this.availableLoading = false;
      }
    },
    //点击生产组当前行
    async handleRowClick(row) {
      this.tableDataDetail.forEach((item) => {
        if (
          row.produceGroupId
            ? item.produceGroupId == row.produceGroupId
            : item.lineId == row.lineId
        ) {
          this.$set(item, "selected", true);
        } else {
          this.$set(item, "selected", false);
        }
      });
      this.formDetail = row; // 获取当前行的信息
      if (this.formDetail.produceGroupName) {
        this.selectGroupName = this.formDetail.produceGroupName;
      } else {
        this.selectGroupName = "生产组产品";
      }

      if (this.formDetail.produceGroupId) {
        await this.getGroupGoodsList();
      } else {
        this.tableDataAvailable = [];
      }
    },
    //生产组信息列表
    async getList() {
      this.loadingDetail = true;
      const res = await produceGroupList(this.queryParams);
      this.tableDataDetail = res.rows;
      if (this.tableDataDetail.length === 0) {
        this.tableDataDetail = [{ lineId: Date.now() }];
      }
      this.handleRowClick(this.tableDataDetail[0]);
      //对比数据
      this.tableDataDetailContrast = JSON.parse(
        JSON.stringify(this.tableDataDetail)
      );
      if (this.formDetail.produceGroupId && this.tableDataDetail?.length > 0) {
        this.tableDataDetail.forEach((item) => {
          if (item.produceGroupId == this.formDetail.produceGroupId) {
            this.$set(item, "selected", true);
          } else {
            this.$set(item, "selected", false);
          }
        });
      }
      this.loadingDetail = false;
    },
    //添加生产组
    async addGroup(produceDeptId) {
      const res = await produceGroupAdd({ ...this.formDetail, produceDeptId });
      this.tableDataDetail.forEach((v) => {
        if (v.lineId == this.formDetail.lineId) {
          this.formDetail.produceGroupId = res?.data?.produceGroupId;
          this.$set(v, "selected", true);
          this.$set(v, "produceDeptId", produceDeptId);
          this.$set(v, "produceGroupId", res?.data?.produceGroupId);
          this.$set(v, "tenantId", res?.data?.tenantId);
          this.tableDataAvailable = [{}];
        } else {
          this.$set(v, "selected", false);
        }
      });

      this.$message({
        type: "success",
        message: "新增生产组成功!",
      });
      // this.getList()
    },
    //删除生产组
    async removeGroup(produceGroupId) {
      await produceGroupRemove(produceGroupId);
      this.$message({
        type: "success",
        message: "删除生产组成功!",
      });
      this.getList();
    },
    //修改生产组
    async updateGroup(produceDeptId) {
      await produceGroupUpdate({ ...this.formDetail, produceDeptId });
      this.tableDataDetail.forEach((item) => {
        if (
          this.formDetail.produceGroupId
            ? item.produceGroupId == this.formDetail.produceGroupId
            : item.lineId == this.formDetail.lineId
        ) {
          this.$set(item, "selected", true);
        } else {
          this.$set(item, "selected", false);
        }
      });
      this.$message({
        type: "success",
        message: "修改生产组成功!",
      });
      // this.getList()
    },
    //生产组商品信息列表
    async getGroupGoodsList() {
      //商品表格加载
      this.availableLoading = true;
      try {
        const res = await produceGroupGoodsList({
          produceGroupId: this.formDetail.produceGroupId,
          pageNum: 1,
          pageSize: 99999,
        });
        this.tableDataAvailable = res.rows;
        if (this.tableDataAvailable.length === 0) {
          this.tableDataAvailable = [{}];
        }
      } catch (error) {
        if (this.tableDataAvailable.length === 0) {
          this.tableDataAvailable = [{}];
        }
      }
      //商品表格加载
      this.availableLoading = false;
    },
    //生产组添加商品
    async addGroupGoods(goodsIdArr) {
      let obj = {
        goodsIds: goodsIdArr,
        produceGroupId: this.formDetail.produceGroupId,
      };
      await produceGroupGoodsAdd(obj);
      this.$message({
        type: "success",
        message: "新增商品成功!",
      });
      this.getGroupGoodsList();
    },
    //生产组删除商品
    async removeGroupGoods(produceGroupGoodsId) {
      await produceGroupGoodsRemove(produceGroupGoodsId);
      this.$message({
        type: "success",
        message: "删除商品成功!",
      });
      this.getGroupGoodsList();
    },
    //表格单元格回车事件
    // keyup (name, event, index, goodsId) {
    //   //判断是否输入编码或条码
    //   if (this.tableDataAvailable[`${index}`][`${name}`]) {
    //     listGoods({
    //       [name]: this.tableDataAvailable[`${index}`][`${name}`]
    //     }).then(response => {
    //       // 判断是否精准匹配 匹配直接赋值 不匹配弹出商品框
    //       if (response.total === 1) {
    //         event.target.blur()
    //         let goodsIdArr = response.rows.map(v => v.goodsId)

    //         this.removeGroupGoods(goodsId)
    //         this.addGroupGoods(goodsIdArr)
    //       } else {
    //         this.queryCode = this.tableDataAvailable[`${index}`][`${name}`]
    //         this.OpenGoods = true
    //       }
    //     })
    //   } else {
    //     //没输入直接弹出商品信息框
    //     event.target.blur()
    //     this.OpenGoods = true
    //   }
    // },
    //选中的商品
    getGoodS(value) {
      if (value) {
        if (this.tableDataAvailable.length > 0) {
          for (let index = 0; index < this.tableDataAvailable.length; index++) {
            value = value.filter((v) => {
              return v.goodsId != this.tableDataAvailable[index].goodsId;
            });
          }
        }
        let goodsIdArr = value.map((v) => v.goodsId);
        if (goodsIdArr.length > 0) {
          this.addGroupGoods(goodsIdArr);
        }
      }
    },
    //原料明细表格当中增加/减少一行方法
    rowDetail(name, index, produceGroupId) {
      if (name === "push") {
        this.tableDataDetail.splice(index + 1, 0, { lineId: Date.now() });
        // this.selectGroupName = '生产组产品'
      } else {
        if (
          this.tableDataDetail.length <= 1 &&
          !this.tableDataDetail[0].produceGroupId
        )
          return;
        this.$confirm("此操作将永久删除该分组, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            if (produceGroupId) {
              this.removeGroup(produceGroupId);
              this.tableDataAvailable = [];
            } else {
              this.tableDataDetail.splice(index, 1);
            }
          })
          .catch();
      }
    },
    //可用物料表格当中增加/减少一行方法
    rowAvailable(name, index, goodsId) {
      if (name === "push") {
        this.tableDataAvailable.splice(index + 1, 0, {});
      } else {
        if (
          this.tableDataAvailable.length <= 1 &&
          !this.tableDataAvailable[0].goodsId
        )
          return;
        this.$confirm("此操作将永久删除该商品, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            if (goodsId) {
              this.removeGroupGoods(goodsId);
            } else {
              this.tableDataAvailable.splice(index, 1);
            }
          })
          .catch();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// 最大盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  .right {
    width: calc(100% - 354px); //生产组数组
    //关系情况
    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      //左边
      .cardDetail {
        margin-bottom: 0;
        width: 45%;
        margin-right: 6px;
      }
      //商品表格卡片
      .cardAvailable {
        margin-bottom: 0;
        width: 53%;
      }
      .tableContent {
        padding: 6px 10px 10px 10px;
      }
    }
  }
}
</style>
